import * as React from 'react'
import { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import PostItem from '../components/PostItem'
import AdSlot from '../components/AdSlot'

const BlogCategoryPage = ({ data }) => {
    const settings = data.settings.data
    const document = data.prismicBlogCategory?.data
    const allPosts = data.posts.nodes

    const perPage = 32
    const adsPerPosts = 8

    const [list, setList] = useState([...allPosts.slice(0, perPage)])
    const [loadMore, setLoadMore] = useState(false)
    const [hasMore, setHasMore] = useState(allPosts.length > perPage)

    const handleLoadMore = () => {
        setLoadMore(true)
    }

    useEffect(() => {
        if (loadMore && hasMore) {
            const currentLength = list.length
            const isMore = currentLength < allPosts.length
            const nextResults = isMore
                ? allPosts.slice(currentLength, currentLength + perPage)
                : []
            setList([...list, ...nextResults])
            setLoadMore(false)
        }
    }, [loadMore, hasMore])

    useEffect(() => {
        const isMore = list.length < allPosts.length
        setHasMore(isMore)
    }, [list])

    return (
        <Layout>
            <Seo
                title={
                    document.seo_title?.text ||
                    prismicH.asText(document.category_title?.richText)
                }
                description={document.seo_description?.text}
                ogImage={document.open_graph_image?.url}
            />
            <div className="bg-gray-100">
                {/* Other Slot */}
                <AdSlot html={data.settings?.data?.other_slot?.text} />
            </div>
            <div className="mx-auto px-6 pt-12 max-w-7xl">
                <div className="mb-10">
                    <h1 className="mb-4 text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-snug">
                        {prismicH.asText(document.category_title?.richText)}
                    </h1>

                    <div className="prose prose-lg max-w-none">
                        <PrismicRichText
                            field={document.category_description?.richText}
                        />
                    </div>
                </div>
                <div className="pb-20">
                    {/* <PrismicRichText
                        className="prose prose-lg max-w-none text-gray-600"
                        field={document.page_content?.richText}
                        //serializeHyperlink={PrismicLink}
                    /> */}

                    <div className="md:grid md:grid-cols-2 lg:grid-cols-4 gap-6 sm:gap-8 md:gap-12 mb-12">
                        {list.map((post, i) => (
                            <React.Fragment key={i}>
                                {i > 0 && i % adsPerPosts === 0 ? (
                                    <div className="md:col-span-2 lg:col-span-4">
                                        <AdSlot
                                            html={
                                                data.settings?.data?.other_slot
                                                    ?.text
                                            }
                                        />
                                    </div>
                                ) : null}
                                <PostItem
                                    settings={settings}
                                    data={data}
                                    post={post}
                                    key={post.uid}
                                />
                            </React.Fragment>
                        ))}
                    </div>

                    <div className="flex items-center justify-center">
                        {hasMore ? (
                            <button
                                className="inline-block px-4 py-2 text-sm font-medium rounded-md bg-gray-200 hover:bg-gray-300 transition"
                                onClick={handleLoadMore}
                                title="Voir plus d'articles"
                            >
                                Voir plus d'articles…
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogCategoryPage

export const query = graphql`
    query BlogCategoryQuery($uid: String!) {
        settings: prismicGeneralSettings {
            data {
                site_name {
                    text
                }
                other_slot {
                    text
                }
            }
        }
        prismicBlogCategory(uid: { eq: $uid }) {
            data {
                category_title {
                    richText
                }
                category_description {
                    richText
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                open_graph_image {
                    url
                }
            }
        }
        posts: allPrismicPost(
            sort: { fields: first_publication_date, order: DESC }
            filter: { data: { post_category: { uid: { eq: $uid } } } }
        ) {
            nodes {
                uid
                first_publication_date(formatString: "MMMM Do, YYYY")
                data {
                    og_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                    post_title_alt {
                        richText
                    }
                    post_title {
                        richText
                    }
                    post_link {
                        link_type
                        type
                        uid
                        url
                    }
                    post_category {
                        uid
                        document {
                            ... on PrismicBlogCategory {
                                id
                                data {
                                    category_title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
